import { useCallback, useEffect, useRef } from 'react';

import { OauthCodeQuery, OauthCodeQueryVariables } from '@generated/OauthCodeQuery';
import { OAUTH_CODE_QUERY } from '@graphql/queries/OauthCodeQuery';
import { useImperativeQuery } from '@hooks/useImperativeQuery/useImperativeQuery';
import { useQueryParams } from '@hooks/useQueryParams';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';

export function useOauthLogin(): void {
  const hasSubmitted = useRef(false);
  const { t } = useTranslation();
  const { searchParams } = useQueryParams();

  const query = useImperativeQuery<OauthCodeQuery, OauthCodeQueryVariables>({
    query: OAUTH_CODE_QUERY,
  });

  const toast = useToast();

  const onSubmit = useCallback(async () => {
    try {
      const clientId = searchParams.get('client_id') ?? '';
      const redirectUri = searchParams.get('redirect_uri') ?? '';
      const responseType = searchParams.get('response_type') ?? '';
      const state = searchParams.get('state') ?? '';

      const response = await query({
        clientId: `client_id=${clientId}`,
        responseType: `response_type=${responseType}`,
        redirectUri: `redirect_uri=${redirectUri}`,
        state: `state=${state}`,
      });

      window.location.replace(response.data!.oauthCode.redirectTo);
    } catch (err) {
      toast.showToast({
        variant: 'critical',
        message: t('generic_errors.other.unknown_error'),
      });
      setTimeout(() => {
        window.history.go(-1);
      }, 2000);
    }
  }, [query, searchParams, t, toast]);

  useEffect(() => {
    if (!hasSubmitted.current) {
      hasSubmitted.current = true;
      onSubmit();
    }
  }, [onSubmit]);
}
