import { useCallback } from 'react';

import { NUMBER_DETAIL_TABS } from '../NumberDetail.decl';
import { NumberDetailHeader } from '../NumberDetailHeader/NumberDetailHeader';
import { ProofOfIdBanner } from '../ProofOfIdBanner/ProofOfIdBanner';

import { NumberDetailTabContainerProps } from './NumberDetailTab.container.decl';

import { IDType, getColor } from '@aircall/tractor-v2';
import { Placeholder } from '@dashboard/extension';
import {
  NUMBER_DETAIL_ROUTE_TAB,
  Tab,
  routeReplacer,
  useNavigateWithParamsReplace,
} from '@dashboard/library';
import { NotFoundError } from '@helpers/errors.helpers';
import { isRegistrationRequiredForNumber } from '@helpers/numbers.helpers';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { NumberDetailBusinessHours } from '@pages/number-detail-business-hours/NumberDetailBusinessHours.page';
import { NumberDetailCallDistribution } from '@pages/number-detail-call-distribution/NumberDetailCallDistribution.page';
import { NumberDetailIntegrationsPage } from '@pages/number-detail-integrations/NumberDetailIntegrations.page';
import { NumberDetailRegistrationStatusPage } from '@pages/number-detail-registration-status/NumberDetailRegistrationStatus.page';
import { NumberDetailsSettingsPage } from '@pages/number-detail-settings/NumberDetailSettings.page';
import { NumberDetailTeamsUsers } from '@pages/number-detail-teams-and-users/NumberDetailTeamsUsers.page';
import styled from '@xstyled/styled-components';
import { t } from 'i18next';
import { Navigate, useParams } from 'react-router-dom';

const BaseTabItem = styled(Tab.BaseTabItem)<{ isRevamp: boolean }>`
  background-color: ${({ isRevamp }) => (isRevamp ? getColor('surface-default') : undefined)};
`;
const TabItem = styled(Tab.Item)<{ isRevamp: boolean }>`
  background-color: ${({ isRevamp }) => (isRevamp ? getColor('surface-default') : undefined)};
`;

export function NumberDetailTabContainer({ line, numberDetail }: NumberDetailTabContainerProps) {
  const { numberId, tab } = useParams();
  const navigate = useNavigateWithParamsReplace();
  const {
    dashboardEnableA2P10DlcRegistrationStatus,
    smartflowsEnabled,
    dashboardEnabledNewSidebar,
  } = useFeatures();
  const shouldShowRegistrationStatus = isRegistrationRequiredForNumber(
    line,
    dashboardEnableA2P10DlcRegistrationStatus
  );
  const availableTabs: string[] = Object.values(NUMBER_DETAIL_TABS);
  const hasSmartflowsEnabled = smartflowsEnabled && numberDetail.flowEditorEnabled;
  const tabs = [
    {
      id: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
      trans: 'number_details.tabs.call_distribution',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.SETTINGS,
      trans: 'number_details.tabs.settings',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.INTEGRATIONS,
      trans: 'number_details.tabs.integrations',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.BUSINESS_HOURS,
      trans: 'number_details.tabs.business_hours',
      shouldDisplay: !hasSmartflowsEnabled,
    },
    {
      id: NUMBER_DETAIL_TABS.TEAMS_AND_USERS,
      trans: 'number_details.tabs.teams&users',
      shouldDisplay: true,
    },
    {
      id: NUMBER_DETAIL_TABS.REGISTRATION_STATUS,
      trans: 'number_details.tabs.registration_status',
      shouldDisplay: shouldShowRegistrationStatus,
    },
  ];
  const displayedTabs = tabs.filter(({ shouldDisplay }) => shouldDisplay);
  const displayedTabIds = displayedTabs.map(({ id }) => id);

  const onTabChange = useCallback(
    (tabId: IDType) => {
      navigate(NUMBER_DETAIL_ROUTE_TAB, { numberId, tab: tabId! });
    },
    [navigate, numberId]
  );

  if (!tab) {
    return (
      <Navigate
        to={routeReplacer(NUMBER_DETAIL_ROUTE_TAB, {
          numberId,
          tab: NUMBER_DETAIL_TABS.CALL_DISTRIBUTION,
        })}
        replace
      />
    );
  }

  if (!availableTabs.includes(tab) || !displayedTabIds.includes(tab)) {
    throw new NotFoundError('Tab not found', { tab });
  }

  return (
    <NumberDetailHeader
      line={line}
      numberDetail={numberDetail}
      activeTabId={tab}
      onTabChange={onTabChange}
      shouldShowRegistrationStatus={shouldShowRegistrationStatus}
    >
      <Tab.Menu>
        {displayedTabs.map(({ id, trans }) => (
          <Tab.MenuItem key={id} id={id}>
            {t(trans)}
          </Tab.MenuItem>
        ))}
      </Tab.Menu>

      <Placeholder
        name='purchase_flow_success_modal'
        parameters={{
          numberId: numberDetail.id,
          numberFormatted: numberDetail.numberFormatted,
          numberName: numberDetail.name,
          numberUuid: numberDetail.numberUuid!,
          verificationState: numberDetail.verificationState ?? '',
        }}
        data-test='new_number_modal'
      />

      <ProofOfIdBanner numberDetail={numberDetail} />

      <Tab.Content>
        {hasSmartflowsEnabled ? (
          <BaseTabItem
            isRevamp={dashboardEnabledNewSidebar}
            id={NUMBER_DETAIL_TABS.CALL_DISTRIBUTION}
          >
            <Placeholder name='flow_editor_view' parameters={{ lineId: numberId }} />
          </BaseTabItem>
        ) : (
          <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.CALL_DISTRIBUTION}>
            <NumberDetailCallDistribution numberDetail={numberDetail} />
          </TabItem>
        )}
        <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.SETTINGS}>
          <NumberDetailsSettingsPage {...numberDetail} />
        </TabItem>
        <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.INTEGRATIONS}>
          <NumberDetailIntegrationsPage />
        </TabItem>
        <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.BUSINESS_HOURS}>
          <NumberDetailBusinessHours numberDetail={numberDetail} />
        </TabItem>
        <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.TEAMS_AND_USERS}>
          <NumberDetailTeamsUsers numberDetail={numberDetail} />
        </TabItem>
        <TabItem isRevamp={dashboardEnabledNewSidebar} id={NUMBER_DETAIL_TABS.REGISTRATION_STATUS}>
          <NumberDetailRegistrationStatusPage numberDetail={numberDetail} />
        </TabItem>
      </Tab.Content>
    </NumberDetailHeader>
  );
}
