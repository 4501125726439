import { useContractCheckAndSign } from '../../components/LandingPage/hooks/useContractCheckAndSign';

import { OnboardingContentSection, OnboardingContentSectionV2, PlansModal } from './components';

import { useFeatures } from '@hooks/useFeatures/useFeatures';

export function OnboardingPage() {
  const {
    dashboardEnableNewStarterModal,
    enableStarterPlan,
    dashboardShowStarterControl,
    showOnboardingTrial,
  } = useFeatures();
  useContractCheckAndSign();

  const showPlansModal =
    dashboardEnableNewStarterModal && (enableStarterPlan || dashboardShowStarterControl);

  return (
    <>
      {showOnboardingTrial ? <OnboardingContentSectionV2 /> : <OnboardingContentSection />}
      {showPlansModal ? <PlansModal /> : undefined}
    </>
  );
}
