import type { SidebarItem } from '@components/Sidebar/sidebarItems';
import { PERMISSIONS_ACTIONS } from '@constants/permissions.constants';
import type { usePermissions } from '@hooks/usePermissions';
import { matchPath } from 'react-router-dom';

export function isRouteRestricted(
  item: Pick<SidebarItem, 'resource' | 'resources'>,
  checkAccess: ReturnType<typeof usePermissions>['checkAccess']
): boolean {
  const resources: SidebarItem['resources'] = (item.resources ?? []).concat(
    item.resource ? [item.resource] : []
  );

  return resources.some((r) => !checkAccess(PERMISSIONS_ACTIONS.READ, r));
}

export function isParentRouteActive(item: SidebarItem, currentPath: string): boolean {
  if (item.children) {
    return item.children.some((child) =>
      Boolean(
        matchPath(
          {
            path: child.to,
            end: false,
          },
          currentPath
        )
      )
    );
  }

  return Boolean(
    matchPath(
      {
        path: item.to,
        end: false,
      },
      currentPath
    )
  );
}
