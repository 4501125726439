import { useCallback, useState } from 'react';

import { useFetchLineIdAndRetry } from './useFetchLineIdAndRetry';

import { logger } from '@config/logger.config';
import { useGraphMutation, useGraphQuery } from '@dashboard/library';
import {
  AddDispatchGroupMutation,
  AddDispatchGroupMutationVariables,
} from '@generated/AddDispatchGroupMutation';
import { CreateLine, CreateLineVariables } from '@generated/CreateLine';
import { GetSignupInformation_getSignupInformation } from '@generated/GetSignupInformation';
import { SearchTotalQuery } from '@generated/SearchTotalQuery';
import { UpdateAgentMutation, UpdateAgentMutationVariables } from '@generated/UpdateAgentMutation';
import { ADD_DISPATCH_GROUP_MUTATION } from '@graphql/mutations/AddDispatchGroupMutation';
import { CREATE_NUMBER_LINE_MUTATION } from '@graphql/mutations/CreateNumberLineMutation';
import { UPDATE_AGENT_MUTATION } from '@graphql/mutations/UpdateAgentMutation';
import { SEARCH_TOTAL_QUERY } from '@graphql/queries/SearchTotalQuery';
import { DISPATCH_GROUP_TYPE } from '@hooks/useAddDispatchGroup';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useRegionProvider } from '@hooks/useRegionProvider/useRegionProvider';

const MAX_RETRIES = 3;

export type UseCreateNumberReturn = {
  createNumber: (error?: Error, attempt?: number) => Promise<void>;
  associateNumberWithUser: (numberId: string) => Promise<boolean>;
  createNumberLoading: boolean;
};

export function useCreateNumber(
  signupInformation?: GetSignupInformation_getSignupInformation
): UseCreateNumberReturn {
  const [createNumberLoading, setCreateNumberLoading] = useState(false);
  const { showOnboardingTrial } = useFeatures();
  const { fetchLineIdAndRetry } = useFetchLineIdAndRetry();
  const {
    currentUser: { ID: currentUserId },
  } = useGlobalData();
  const [addDispatchGroup] = useGraphMutation<
    AddDispatchGroupMutation,
    AddDispatchGroupMutationVariables
  >(ADD_DISPATCH_GROUP_MUTATION);

  const [updateAgent] = useGraphMutation<UpdateAgentMutation, UpdateAgentMutationVariables>(
    UPDATE_AGENT_MUTATION
  );

  const { data: totals } = useGraphQuery<SearchTotalQuery>(SEARCH_TOTAL_QUERY, {
    skip: !showOnboardingTrial,
  });
  const [createLineMutation] = useGraphMutation<CreateLine, CreateLineVariables>(
    CREATE_NUMBER_LINE_MUTATION
  );
  // Get region details using custom hook
  const { getRegionId } = useRegionProvider(signupInformation);

  const associateNumberWithUser = useCallback(
    async (numberId: string) => {
      await addDispatchGroup({
        variables: {
          numberId,
          input: {
            data: {
              type: 'dispatch_group',
              attributes: {
                priority: 1,
              },
              relationships: {
                dispatchable: {
                  data: {
                    id: currentUserId,
                    type: DISPATCH_GROUP_TYPE.USER,
                  },
                },
              },
            },
          },
        },
      });
      await updateAgent({
        variables: {
          input: {
            ID: currentUserId,
            defaultOutPhoneNumberID: numberId,
          },
        },
      });
      return true;
    },
    [currentUserId, addDispatchGroup, updateAgent]
  );

  const createNumberHandler = useCallback(async (): Promise<void> => {
    if (!showOnboardingTrial) {
      return;
    }
    if (!signupInformation?.phoneNumber) {
      logger.error(
        'useCreateNumber: Unable to create number, phone number not available in signup information'
      );
      throw new Error('Phone number not available');
    }
    // Do not run if totals are not available or if there are already numbers
    if (
      (!totals?.searchLines?.total && totals?.searchLines?.total !== 0) ||
      totals.searchLines.total > 0
    ) {
      logger.error('useCreateNumber: Skipping number creation as line already exists');
      return;
    }
    setCreateNumberLoading(true);
    const regionId = await getRegionId();
    if (!regionId) {
      logger.error('useCreateNumber: Failed to create number - Could not retrieve region');
      throw new Error('Failed to retrieve region');
    }
    const name = 'default-number';
    const capabilities = ['voice'];

    const inputData = {
      name: name.trim(),
      regionId,
      capabilities,
      phoneType: 'geographic_local',
    };
    logger.info('useCreateNumber: Creating number', { data: inputData });
    // Execute the mutation to create a line
    try {
      const { data } = await createLineMutation({
        variables: {
          input: inputData,
        },
        update: (cache) => {
          cache.updateQuery({ query: SEARCH_TOTAL_QUERY }, (cachedData) => ({
            ...cachedData,
            searchLines: {
              ...cachedData.searchLines,
              total: cachedData.searchLines.total + 1,
            },
          }));
        },
      });
      if (!data?.createLine) {
        logger.error('useCreateNumber: Could not create number', { data });
        throw new Error('Could not create number');
      }
      logger.info('useCreateNumber: Number created', { data });
      let lineId: string | null = data.createLine.ID;
      if (data?.createLine.newPurchasingFlowEnabled) {
        const legacyNumberId = await fetchLineIdAndRetry(data.createLine.ID);
        if (!legacyNumberId) {
          logger.error('useCreateNumber: Failed to fetch legacy number ID');
          throw new Error('Failed to fetch legacy number ID');
        }
        logger.info('useCreateNumber: Returning Legacy number ID', { id: legacyNumberId });
        lineId = legacyNumberId;
      }
      logger.info('useCreateNumber: line id identified', { id: data?.createLine.ID });
      await associateNumberWithUser(lineId);
    } finally {
      setCreateNumberLoading(false);
    }
  }, [
    createLineMutation,
    fetchLineIdAndRetry,
    getRegionId,
    showOnboardingTrial,
    signupInformation?.phoneNumber,
    totals?.searchLines?.total,
    associateNumberWithUser,
  ]);

  const createNumber = useCallback(
    async (error?: unknown, attempt = 1): Promise<void> => {
      if (attempt > MAX_RETRIES) {
        logger.error('useCreateNumber: Failed to create number after maximum attempts', { error });
        throw error;
      }
      try {
        logger.info('useCreateNumber: Creating number', { attempt });
        await createNumberHandler();
      } catch (e) {
        logger.error(`useCreateNumber: Failed to create number on attempt ${attempt}`, { error });
        await createNumber(e, attempt + 1);
      }
    },
    [createNumberHandler]
  );
  return { createNumber, associateNumberWithUser, createNumberLoading };
}
