/*
// BEFORE

{
  "voices": {
    "cmn": { "Zhiyu": "Zhiyu" },
    "da": { "Mads": "Mads", "Naja": "Naja" },
    "nl": { "Ruben": "Ruben", "Lotte": "Lotte" },
    "en": {
      "Joanna": "Joanna",
      "Ivy": "Ivy",
      "Kendra": "Kendra",
      "Kimberly": "Kimberly",
      "Salli": "Salli",
      "Joey": "Joey",
      "Justin": "Justin",
      "Matthew": "Matthew",
      "Amy": "Amy (British)",
      "Emma": "Emma (British)",
      "Brian": "Brian (British)",
      "Nicole": "Nicole (Australian)",
      "Russell": "Russell (Australian)",
      "Geraint": "Geraint (Welsh)",
      "Raveena": "Raveena (Indian)"
    },
    "fr": {
      "Celine": "Céline",
      "Lea": "Léa",
      "Mathieu": "Mathieu",
      "Chantal": "Chantal (Canadian)"
    },
    "de": { "Marlene": "Marlene", "Vicki": "Vicki", "Hans": "Hans" },
    "it": { "Giorgio": "Giorgio", "Carla": "Carla", "Bianca": "Bianca" },
    "jp": { "Mizuki": "Mizuki", "Takumi": "Takumi" },
    "ko": { "Seoyeon": "Seoyeon" },
    "nb": { "Liv": "Liv" },
    "pl": { "Jacek": "Jacek", "Ewa": "Ewa", "Maja": "Maja", "Jan": "Jan" },
    "pt": {
      "Ines": "Inês (European)",
      "Cristiano": "Cristiano (European)",
      "Vitoria": "Vitória (Brazilian)",
      "Ricardo": "Ricardo (Brazilian)"
    },
    "ro": { "Carmen": "Carmen" },
    "es": {
      "Penelope": "Penélope (US)",
      "Miguel": "Miguel (US)",
      "Mia": "Mia (Mexican)",
      "Conchita": "Conchita (European)",
      "Lucia": "Lucia (European)",
      "Enrique": "Enrique (European)"
    },
    "sv": { "Astrid": "Astrid" },
    "tr": { "Filiz": "Filiz" }
  },
  "languages": {
    "cmn": "Chinese",
    "da": "Danish",
    "nl": "Dutch",
    "en": "English",
    "fr": "French",
    "de": "German",
    "it": "Italian",
    "jp": "Japanese",
    "ko": "Korean",
    "nb": "Norwegian",
    "pl": "Polish",
    "pt": "Portuguese",
    "ro": "Romanian",
    "es": "Spanish",
    "sv": "Swedish",
    "tr": "Turkish"
  }
}

// AFTER

{
  "languages": [
    {
      id: "en",
      locale: "en",
      label: "English",
      voices: [
        {
          id: "en_Joanna",
          name: "Joanna",
          label: "Joanna",
        },
        {
          id: "en_Nicole",
          name: "Nicole",
          label: "Nicole (Australian)",
        },
        ...
      ]
    },
    ...
  ]
}
*/

import {
  TextToSpeechOptionsQuery_textToSpeechOptions,
  TextToSpeechOptionsQuery_textToSpeechOptions_languages,
} from '@generated/TextToSpeechOptionsQuery';

export default async function textToSpeechOptionsTransformer(
  res: Response
): Promise<TextToSpeechOptionsQuery_textToSpeechOptions> {
  const resData = await res.json();

  const languages = Object.keys(resData.voices).map(
    (lang) =>
      ({
        __typename: 'TtsLanguage',
        id: lang,
        locale: lang,
        label: resData.languages[lang],
        voices: Object.keys(resData.voices[lang]).map((voiceName) => ({
          __typename: 'RestTtsVoice',
          id: `${lang}_${voiceName}`,
          name: voiceName,
          label: resData.voices[lang][voiceName],
        })),
      } as TextToSpeechOptionsQuery_textToSpeechOptions_languages)
  );

  return { __typename: 'TtsOptions', languages };
}
