import React, { useCallback } from 'react';

import { HeaderUserProfileDropdownProps } from './HeaderUserProfileDropdown.decl';

import { ChevronDownOutlined } from '@aircall/icons';
import {
  Button,
  Dropdown,
  Menu,
  MenuItem,
  Spacer,
  Typography,
  getColor,
} from '@aircall/tractor-v2';
import { ExternalLink } from '@components/Link';
import { UserAvatar } from '@components/UserAvatarV2';
import { ResellerConfig } from '@config/resellers.config';
import { USER_DETAIL_ROUTE_TAB } from '@constants/routes.constants';
import { getFullName, getInitials } from '@dashboard/library';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useCurrentUser } from '@hooks/useCurrentUser/useCurrentUser';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useNavigateWithParamsReplace } from '@hooks/useNavigateWithParamsReplace';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';

const DropdownLinkItem = styled(ExternalLink)`
  background-color: ${getColor('surface-interactive-warning-ghost-active')};
  color: ${getColor('surface-interactive-warning-active')};

  &:hover {
    color: ${getColor('surface-interactive-warning-active')};
    text-decoration: none;
  }
`;

const HeaderUserProfileDropdown = React.memo(
  ({ showEditProfile = true, showReferAFriendButton = false }: HeaderUserProfileDropdownProps) => {
    const {
      actions: { signOut },
    } = useAuthenticationState();
    const { currentUser } = useCurrentUser();
    const { t } = useTranslation();
    const navigate = useNavigateWithParamsReplace();
    const { dashboardEnabledNewSidebar } = useFeatures();

    const goToUserProfile = useCallback(() => {
      navigate(USER_DETAIL_ROUTE_TAB, {
        userId: currentUser!.ID,
        tab: 'general',
      });
    }, [navigate, currentUser]);

    if (dashboardEnabledNewSidebar) {
      return null;
    }

    return (
      <Dropdown
        trigger={
          <Button data-test='dropdown-button' mode='ghost' size='xSmall' variant='secondary'>
            {currentUser && (
              <Spacer space='xxs' alignItems='center'>
                <UserAvatar
                  id={currentUser.ID}
                  initials={getInitials(currentUser.firstName, currentUser.lastName)}
                  size='small'
                  src={currentUser.pictureURL}
                />
                {getFullName(currentUser.firstName, currentUser.lastName)}
              </Spacer>
            )}
            <ChevronDownOutlined />
          </Button>
        }
      >
        <Menu>
          {showEditProfile && (
            <MenuItem data-test='dropdown-item-edit-profile' onClick={goToUserProfile}>
              {t('header.user_dropdown.edit_profile')}
            </MenuItem>
          )}
          <MenuItem data-test='dropdown-item-logout' onClick={signOut}>
            <Typography color='critical-500'>{t('header.user_dropdown.logout')}</Typography>
          </MenuItem>
          {showReferAFriendButton && (
            <MenuItem data-test='dropdown-item-refer-a-friend'>
              <DropdownLinkItem
                borderRadius='sm'
                data-test='refer-a-friend-button'
                href={t(
                  `resellers.${ResellerConfig.identifier}.header.user_dropdown.refer_a_friend.url`
                )}
                py='xxs'
                px='s'
                target='_blank'
                variant='bodySemiboldM'
              >
                {t('header.user_dropdown.refer_a_friend.title')}
              </DropdownLinkItem>
            </MenuItem>
          )}
        </Menu>
      </Dropdown>
    );
  }
);

export default HeaderUserProfileDropdown;
