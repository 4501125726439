import { useContext } from 'react';

import { UseFeaturesReturnType } from './useFeatures.decl';

import { FeaturesFlagsContext } from '@components/FeaturesFlagsProvider';
import { ResellerConfig } from '@config/resellers.config';
import { useAuthenticationState } from '@hooks/useAuthenticationState';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { useReseller } from '@hooks/useReseller/useReseller';

export const MAX_NUMBER_OF_TEAMS_ADVANCED = 10000;
export const MAX_NUMBERS_OF_NUMBERS_IN_TRIAL = 10;
const DEFAULT_DAILY_CALL_LIMIT_MINUTES = 40;
export const ONBOARDING_TRIAL_DAILY_CALL_LIMIT_MINUTES = 100;

/**
 * Hook providing feature flags.
 * @returns an object holding application feature flags
 */
export function useFeatures(): UseFeaturesReturnType {
  const { currentCompany, currentPlan, isTrial } = useGlobalData();
  const { isReseller } = useReseller();
  const featureFlags = useContext(FeaturesFlagsContext);
  const {
    authState: { isCognitoMigrated },
  } = useAuthenticationState();

  const {
    featureSet: {
      shouldShowCompanyAccountPage,
      shouldShowHelpLinks,
      shouldShowReferAFriendButton,
      enableSaml: resellerEnableSaml,
    },
  } = ResellerConfig;
  const {
    featuresAllowed: {
      isTeamsAdvancedAllowed,
      isQueueCallbackAllowed,
      isMandatoryTaggingAllowed,
      isSipSettingsAllowed,
      isCompanyAccountAllowed,
      isSupportLinksAllowed,
      allow10NumbersInTrial,
      isAdvancedAnalyticsAllowed,
      isLiveFeedAllowed,
    },
  } = currentCompany;

  const maxNumberOfTeams = isTeamsAdvancedAllowed
    ? MAX_NUMBER_OF_TEAMS_ADVANCED
    : currentPlan.features.nbTeamsMax;

  /**
   * This is the setting that will check the max number of numbers
   * if the company has a feature flag active to allow at least 10
   * numbers we will set the amount of numbers as 10 or we get the amount from the plan.
   */
  const maxNumberOfNumbers =
    isTrial && allow10NumbersInTrial
      ? MAX_NUMBERS_OF_NUMBERS_IN_TRIAL
      : currentPlan.features.nbLinesMax;

  const showAnalyticsPlus = !!featureFlags?.showLookerInAnalytics;
  const showLegacyAnalytics = !!featureFlags?.showLegacyAnalytics;
  const showNewAnalytics = !!featureFlags?.showNewAnalytics;
  const dashboardExtensionEnableIntegrationExtension =
    !!featureFlags?.dashboardExtensionEnableIntegrationExtension;
  const showInbound = !!featureFlags?.showInbound;
  const analyticsAvailabilitiesDashboard = !!featureFlags?.analyticsAvailabilitiesDashboard;
  const showAdvancedV4Analytics = !!featureFlags?.showAdvancedV4Analytics;
  const showAnalyticsFiltersInDashboard = !!featureFlags?.showAnalyticsFiltersInDashboard;
  const showCallQualityDashboard = !!featureFlags?.showCallQualityDashboard;
  const showCallQualityDashboardPro = !!featureFlags?.showCallQualityDashboardPro;
  const showInboundActivityDashboardPro = !!featureFlags?.showInboundActivityDashboardPro;
  const showOutboundActivityDashboardPro = !!featureFlags?.showOutboundActivityDashboardPro;
  const showUsersActivityDashboardPro = !!featureFlags?.showUsersActivityDashboardPro;
  const showUnansweredCallsDashboardPro = !!featureFlags?.showUnansweredCallsDashboardPro;
  const showGoalOrientedForProOnAnalyticsPlusException =
    !!featureFlags?.showGoalOrientedForProOnAnalyticsPlusException;
  const dashboardHideNumberAvailabilityStatus =
    !!featureFlags?.dashboardHideNumberAvailabilityStatus;
  const enableCallHistoryFeedbackButton = !!featureFlags?.enableCallHistoryFeedbackButton;
  const enablePermissionsNotifications = !!featureFlags?.enablePermissionsNotifications;
  const dashboardOnboardingFirefoxExtensionDisplayed =
    !!featureFlags?.dashboardOnboardingFirefoxExtensionDisplayed;
  const enableSaml =
    isCognitoMigrated && !!featureFlags?.useSamlAuthentication && resellerEnableSaml;
  const dashboardPlanUpgradeEnabled = !!featureFlags?.dashboardPlanUpgradeEnabled;
  const useFirstPaymentCheck = !!featureFlags?.useFirstPaymentCheck;
  const dashboardPlanChangesEnabled = !!featureFlags?.dashboardPlanChangesEnabled;
  const useInternalApiToUpdateBillingFieldsInDashboard =
    !!featureFlags?.useInternalApiToUpdateBillingFieldsInDashboard;
  const useInternalApiCustomerProvisioningQueries =
    !!featureFlags?.useInternalApiCustomerProvisioningQueries;
  const dashboardNewOverviewEnabled = !!featureFlags?.dashboardNewOverviewEnabled;
  // FF dashboardSmartflowsEnabled is to handle the feature on the dashboard
  // FF useSmartflows is to handle the feature from the backend side
  // The smartflows feature is enabled only when these 2 FF are enabled
  const dashboardSmartflowsEnabled =
    !!featureFlags?.dashboardSmartflowsEnabled && !!featureFlags?.useSmartflows;
  const smartflowsEnabled = !!featureFlags?.useSmartflows;
  const smartflowMigrationEnabled =
    smartflowsEnabled && !!featureFlags?.dashboardEnableSmartflowsMigration;
  const advancedAiPackage = !!featureFlags?.advancedAiPackage;
  const dashboardExtensionEnableConversationsCenterAiQuestions =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterAiQuestions;
  const dashboardExtensionEnableConversationsCenterCallScoring =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterCallScoring;
  const dashboardExtensionEnableConversationsCenterAma =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterAma;
  const dashboardExtensionEnableConversationsCenterNextActionsItems =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterNextActionsItems;
  const dashboardEnabledNewSidebar = !!featureFlags?.dashboardEnabledNewSidebar;
  const advancedAiPackageSentimentAnalysis = !!featureFlags?.advancedAiPackageSentimentAnalysis;
  const newIntegrationDashboardUi = !!featureFlags?.newIntegrationDashboardUi;
  const dashboardExtensionEnableInternalTestExt =
    !!featureFlags.dashboardExtensionEnableInternalTestExt;
  const dashboardExtensionEnableConversationsCenterExt =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterExt;
  const oldOverviewReleaseBannerEnabled = !!featureFlags?.oldOverviewReleaseBannerEnabled;
  const dashboardExtensionEnableNumberExtension =
    !!featureFlags?.dashboardExtensionEnableNumberExtension;
  const dashboardExtensionEnableFlowEditorExtension =
    !!featureFlags?.dashboardExtensionEnableFlowEditorExtension;
  const dashboardExtensionEnableTeamExtension =
    !!featureFlags?.dashboardExtensionEnableTeamExtension;
  const dashboardExtensionEnableUserExtension =
    !!featureFlags?.dashboardExtensionEnableUserExtension;
  const dashboardExtensionEnableLiveMonitoringExtension =
    !!featureFlags?.dashboardExtensionEnableLiveMonitoringExtension;
  const fraudEmailChangeValidation = !!featureFlags?.fraudEmailChangeValidation;
  const fraudPasswordChangeValidation = !!featureFlags?.fraudPasswordChangeValidation;
  const fraudAddPaymentCardValidation = !!featureFlags?.fraudAddPaymentCardValidation;
  const dashboardExtensionEnableAccountExtension =
    !!featureFlags?.dashboardExtensionEnableAccountExtension;
  const useRevampedPlanSection = !!featureFlags?.useRevampedPlanSection;
  const dashboardAgentsCreationDisplayNewMaxWarning =
    !!featureFlags?.dashboardAgentsCreationDisplayNewMaxWarning;
  const dashboardNumbersCreationDisplayNewMaxWarning =
    !!featureFlags?.dashboardNumbersCreationDisplayNewMaxWarning;
  const useLicenseBasedSubscription = !!featureFlags?.useLicenseBasedSubscription;
  const useAuInvoiceEndpoints = !!featureFlags?.useAuInvoiceEndpoints;
  const useSelfServiceCancellation = !!featureFlags?.useSelfServiceCancellation;
  const dashboardListViewFilters = !!featureFlags?.dashboardListViewFilters;
  const workatoIframeShowOnlyConnectButton = !!featureFlags?.workatoIframeShowOnlyConnectButton;
  const dashboardExtensionEnableCallTimelineExtension =
    !!featureFlags?.dashboardExtensionEnableCallTimelineExtension;
  const dashboardExtensionEnableCalendarMgmtExtension =
    !!featureFlags?.dashboardExtensionEnableCalendarMgmtExtension;
  const dashboardEnableCallTimelinePage = !!featureFlags?.dashboardEnableCallTimelinePage;
  const enablePusherConfig = !!featureFlags?.enablePusherConfig;
  const enableGainsightEvents = !!featureFlags?.enableGainsightEvents;
  const useEmailMfa = !!featureFlags?.useEmailMfa;
  const useLearningLab = !!featureFlags?.useLearningLab;
  const enableGclDirectDebitMandate = !!featureFlags?.enableGclDirectDebitMandate;
  const dashboardBlockDirectDebitOptionInCaseSelfSubscription =
    !!featureFlags?.dashboardBlockDirectDebitOptionInCaseSelfSubscription;
  const dashboardExtractReport = !!featureFlags?.dashboardExtractReport;
  const showAnalyticsplusTeaserMenu = !!featureFlags?.showAnalyticsplusTeaserMenu;
  const useInternalApiCountriesCurrenciesList =
    !!featureFlags?.useInternalApiCountriesCurrenciesList;
  const enableTrialAddonsForAdvancedMessaging =
    !!featureFlags?.enableTrialAddonsForAdvancedMessaging;
  const enableTrialAddonsForAircallAi = !!featureFlags?.enableTrialAddonsForAircallAi;
  const enableTrialAddonsForAircallSurveys = !!featureFlags?.enableTrialAddonsForAircallSurveys;
  const enableTrialAddonsForAnalyticsPlus = !!featureFlags?.enableTrialAddonsForAnalyticsPlus;
  const selfCancellationWithAutoApprovalEnabled =
    !!featureFlags?.selfCancellationWithAutoApprovalEnabled;
  const selfCancellationWithApprovalRequiredEnabled =
    !!featureFlags?.selfCancellationWithApprovalRequiredEnabled;
  const dashboardEnableA2P10DlcRegistrationStatus =
    !!featureFlags?.dashboardEnableA2P10DlcRegistrationStatus;
  const enableAircallWorkspaceMigration = !!featureFlags?.enableAircallWorkspaceMigration;
  const enableStarterPlan = !!featureFlags?.enableStarterPlan;
  const enableStarterPlanForAllCountries = !!featureFlags?.enableStarterPlanForAllCountries;
  const dashboardEnableNewStarterModal = !!featureFlags?.dashboardEnableNewStarterModal;
  const dashboardShowStarterControl = !!featureFlags?.dashboardShowStarterControl;
  const enableProductCatalogDashboard = !!featureFlags?.enableProductCatalogDashboard;
  const flowEditorEnableSetupFlows = !!featureFlags?.flowEditorEnableSetupFlows;
  const flowEditorEnableExperimentalWidget = !!featureFlags?.flowEditorEnableExperimentalWidget;
  const showOnboardingTrial = !!featureFlags?.showOnboardingTrial;
  const enableDiscountDuringSelfCancellation = !!featureFlags?.enableDiscountDuringSelfCancellation;
  const dailyCallLimitMinutes = showOnboardingTrial
    ? ONBOARDING_TRIAL_DAILY_CALL_LIMIT_MINUTES
    : DEFAULT_DAILY_CALL_LIMIT_MINUTES;
  const dashboardExtensionEnableConversationsCenterInsightBoard =
    !!featureFlags?.dashboardExtensionEnableConversationsCenterInsightBoard;
  const enableWhatsappIntegration = !!featureFlags?.enableWhatsappIntegration;
  const dashboardStarterEnforceTos = !!featureFlags?.dashboardStarterEnforceTos;
  const dashboardHideActivityfeed = !!featureFlags?.dashboardHideActivityfeed;

  return {
    selfCancellationWithAutoApprovalEnabled,
    selfCancellationWithApprovalRequiredEnabled,
    dashboardExtensionEnableConversationsCenterCallScoring,
    useRevampedPlanSection,
    enableAircallWorkspaceMigration,
    enableStarterPlan,
    enableStarterPlanForAllCountries,
    enableDiscountDuringSelfCancellation,
    dashboardEnableNewStarterModal,
    dashboardShowStarterControl,
    useLicenseBasedSubscription,
    useSelfServiceCancellation,
    useAuInvoiceEndpoints,
    dashboardExtensionEnableAccountExtension,
    dashboardExtensionEnableConversationsCenterInsightBoard,
    advancedAiPackage,
    dashboardExtensionEnableNumberExtension,
    dashboardExtensionEnableTeamExtension,
    dashboardExtensionEnableUserExtension,
    dashboardExtensionEnableLiveMonitoringExtension,
    dashboardEnableA2P10DlcRegistrationStatus,
    isAdvancedAnalyticsAllowed,
    isSipSettingsAllowed,
    isLiveFeedAllowed,
    isMandatoryTaggingAllowed,
    isCompanyAccountAllowed: shouldShowCompanyAccountPage && isCompanyAccountAllowed && !isReseller,
    isSupportLinksAllowed: shouldShowHelpLinks && isSupportLinksAllowed,
    isQueueCallbackAllowed,
    maxNumberOfTeams,
    maxNumberOfNumbers,
    dailyCallLimitInMinutes: dailyCallLimitMinutes,
    showReferAFriendButton: shouldShowReferAFriendButton,
    showAnalyticsPlus,
    showLegacyAnalytics,
    showNewAnalytics,
    showInbound,
    analyticsAvailabilitiesDashboard,
    showAdvancedV4Analytics,
    showAnalyticsFiltersInDashboard,
    showCallQualityDashboard,
    showCallQualityDashboardPro,
    showInboundActivityDashboardPro,
    showOutboundActivityDashboardPro,
    showUsersActivityDashboardPro,
    showUnansweredCallsDashboardPro,
    showGoalOrientedForProOnAnalyticsPlusException,
    dashboardHideNumberAvailabilityStatus,
    dashboardNewOverviewEnabled,
    enableCallHistoryFeedbackButton,
    dashboardExtensionEnableInternalTestExt,
    dashboardOnboardingFirefoxExtensionDisplayed,
    dashboardExtensionEnableFlowEditorExtension,
    dashboardSmartflowsEnabled,
    smartflowsEnabled,
    smartflowMigrationEnabled,
    enableSaml,
    dashboardPlanUpgradeEnabled,
    useFirstPaymentCheck,
    dashboardPlanChangesEnabled,
    useInternalApiCustomerProvisioningQueries,
    useInternalApiToUpdateBillingFieldsInDashboard,
    useInternalApiCountriesCurrenciesList,
    enableTrialAddonsForAdvancedMessaging,
    enableTrialAddonsForAircallAi,
    enableTrialAddonsForAircallSurveys,
    enableTrialAddonsForAnalyticsPlus,
    dashboardExtensionEnableConversationsCenterExt,
    dashboardExtensionEnableIntegrationExtension,
    dashboardExtensionEnableCallTimelineExtension,
    dashboardExtensionEnableCalendarMgmtExtension,
    dashboardEnableCallTimelinePage,
    oldOverviewReleaseBannerEnabled,
    dashboardAgentsCreationDisplayNewMaxWarning,
    dashboardNumbersCreationDisplayNewMaxWarning,
    enablePusherConfig,
    enableGainsightEvents,
    dashboardHideActivityfeed,
    enablePermissionsNotifications,
    fraudEmailChangeValidation,
    fraudPasswordChangeValidation,
    fraudAddPaymentCardValidation,
    dashboardListViewFilters,
    workatoIframeShowOnlyConnectButton,
    useEmailMfa,
    useLearningLab,
    enableGclDirectDebitMandate,
    dashboardBlockDirectDebitOptionInCaseSelfSubscription,
    dashboardExtractReport,
    showAnalyticsplusTeaserMenu,
    advancedAiPackageSentimentAnalysis,
    enableProductCatalogDashboard,
    flowEditorEnableSetupFlows,
    flowEditorEnableExperimentalWidget,
    dashboardExtensionEnableConversationsCenterAma,
    newIntegrationDashboardUi,
    showOnboardingTrial,
    dashboardEnabledNewSidebar,
    dashboardExtensionEnableConversationsCenterAiQuestions,
    dashboardExtensionEnableConversationsCenterNextActionsItems,
    enableWhatsappIntegration,
    dashboardStarterEnforceTos,
  };
}
