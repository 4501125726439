import { gql } from '@apollo/client';

export const FEATURES_FLAGS_QUERY = gql`
  query FeaturesFlagsQuery {
    featureFlags @rest(type: "RestFeatureFlag", path: "feature_flags", endpoint: "v3") {
      advancedAiPackage
      dashboardExtensionEnableConversationsCenterCallScoring
      dashboardExtensionEnableConversationsCenterAiQuestions
      dashboardExtensionEnableConversationsCenterAma
      dashboardExtensionEnableConversationsCenterNextActionsItems
      showLookerInAnalytics
      showLegacyAnalytics
      showNewAnalytics
      dashboardHideNumberAvailabilityStatus
      dashboardNewOverviewEnabled
      enableCallHistoryFeedbackButton
      showInbound
      analyticsAvailabilitiesDashboard
      showAdvancedV4Analytics
      showAnalyticsFiltersInDashboard
      showCallQualityDashboard
      showCallQualityDashboardPro
      showInboundActivityDashboardPro
      showOutboundActivityDashboardPro
      showUsersActivityDashboardPro
      showUnansweredCallsDashboardPro
      showGoalOrientedForProOnAnalyticsPlusException
      enablePermissionsNotifications
      dashboardOnboardingFirefoxExtensionDisplayed
      disableFrontendTracking
      dashboardSmartflowsEnabled
      useSamlAuthentication
      dashboardPlanUpgradeEnabled
      useFirstPaymentCheck
      dashboardPlanChangesEnabled
      useInternalApiCustomerProvisioningQueries
      useInternalApiToUpdateBillingFieldsInDashboard
      useInternalApiCountriesCurrenciesList
      enableTrialAddonsForAdvancedMessaging
      enableTrialAddonsForAircallAi
      enableTrialAddonsForAircallSurveys
      enableTrialAddonsForAnalyticsPlus
      useSmartflows
      dashboardEnableSmartflowsMigration
      oldOverviewReleaseBannerEnabled
      fraudEmailChangeValidation
      fraudPasswordChangeValidation
      fraudAddPaymentCardValidation
      useRevampedPlanSection
      enableAircallWorkspaceMigration
      enableStarterPlan
      enableStarterPlanForAllCountries
      enableDiscountDuringSelfCancellation
      dashboardEnableNewStarterModal
      dashboardShowStarterControl
      useLicenseBasedSubscription
      useSelfServiceCancellation
      selfCancellationWithAutoApprovalEnabled
      selfCancellationWithApprovalRequiredEnabled
      useAuInvoiceEndpoints
      ringToViaApiEnabled
      dashboardAgentsCreationDisplayNewMaxWarning
      dashboardNumbersCreationDisplayNewMaxWarning
      dashboardListViewFilters
      workatoIframeShowOnlyConnectButton
      enablePusherConfig
      enableGainsightEvents
      useEmailMfa
      enableGclDirectDebitMandate
      dashboardEnableCallTimelinePage
      dashboardBlockDirectDebitOptionInCaseSelfSubscription
      dashboardExtractReport
      showAnalyticsplusTeaserMenu
      useEmailMfa
      useLearningLab
      enableGclDirectDebitMandate
      dashboardEnableCallTimelinePage
      dashboardBlockDirectDebitOptionInCaseSelfSubscription
      dashboardExtractReport
      showAnalyticsplusTeaserMenu
      dashboardEnableA2P10DlcRegistrationStatus
      dashboardEnabledNewSidebar
      dashboardHideActivityfeed

      # Related to Dasboard Extension
      # should stay because of the logic present in ModuleFederationProvider.tsx
      dashboardExtensionEnableInternalTestExt
      dashboardExtensionEnableFlowEditorExtension
      dashboardExtensionEnableNumberExtension
      dashboardExtensionEnableIntegrationExtension
      dashboardExtensionEnableConversationsCenterExt
      dashboardExtensionEnableCallTimelineExtension
      dashboardExtensionEnableCalendarMgmtExtension
      dashboardExtensionEnableAccountExtension
      dashboardExtensionEnableTeamExtension
      dashboardExtensionEnableUserExtension
      dashboardExtensionEnableLiveMonitoringExtension
      dashboardExtensionEnableConversationsCenterInsightBoard
      advancedAiPackageSentimentAnalysis
      enableProductCatalogDashboard
      flowEditorEnableSetupFlows
      flowEditorEnableExperimentalWidget
      newIntegrationDashboardUi
      showOnboardingTrial
      enableWhatsappIntegration
      dashboardStarterEnforceTos
    }
  }
`;
