import { Fragment, useMemo, useRef, useState } from 'react';

import { SidebarItem, SIDEBAR_ITEMS_CONFIG } from '../../../Sidebar/sidebarItems';
import { sortByPosition } from '../../../Sidebar/utils';
import { StatsAnalyticsPlusAdItem } from '../StatsAnalyticsPlusAdItem/StatsAnalyticsPlusAdItem';

import { SIDENAV_ITEMS_ADDONS_CONFIG } from './SidenavMain.constants';
import { MainRoutesProps, SidenavMainProps } from './SidenavMain.decl';
import { ScrollableContainer, SidenavDropdownContainer, SidenavItemLi } from './SidenavMain.styles';
import { isParentRouteActive, isRouteRestricted } from './SidenavMain.utils';

import {
  Flex,
  SidenavDropdownItem,
  SidenavDropdownItemLabel,
  SidenavDropdownSeparator,
  SidenavItem,
  SidenavItemIndicator,
  SidenavItemProps,
  SidenavItemTitle,
} from '@aircall/tractor-v2';
import { ReactComponent as LockCircledIcon } from '@assets/icons/lock_circled_icon.svg';
import { ReactComponent as ActivityFeedIcon } from '@assets/icons/sidenav/activity_feed.svg';
import { ReactComponent as AnalyticsIcon } from '@assets/icons/sidenav/analytics.svg';
import { ReactComponent as CallSettingsIcon } from '@assets/icons/sidenav/call_settings.svg';
import { ReactComponent as ConversationCenterIcon } from '@assets/icons/sidenav/conversation_center.svg';
import { ReactComponent as IntegrationsIcon } from '@assets/icons/sidenav/integrations.svg';
import { ReactComponent as LiveMonitoringIcon } from '@assets/icons/sidenav/live_monitoring.svg';
import { ReactComponent as NumbersIcon } from '@assets/icons/sidenav/numbers.svg';
import { ReactComponent as UsersIcon } from '@assets/icons/sidenav/users.svg';
import { AnalyticsPlusAdModal } from '@components/AnalyticsPlusAdModal';
import { formatToSidebarItem } from '@components/Sidebar/SidebarContent';
import { TEAMS_ROUTE, USERS_AND_TEAMS_GROUP, USERS_ROUTE } from '@constants/routes.constants';
import { useCompanyAddons } from '@hooks/useCompanyAddons/useCompanyAddons';
import { getActiveAddonTrialByName } from '@hooks/useCompanyAddons/utils';
import { useFeatures } from '@hooks/useFeatures/useFeatures';
import { useGetEnableTrialAddonsFeatureFlags } from '@hooks/useGetEnableTrialAddonsFeatureFlags';
import { useHasScrollableContent } from '@hooks/useHasScrollableContent/useHasScrollableContent';
import { useModuleFederationContext } from '@hooks/useModuleFederationContext/useModuleFederationContext';
import { usePermissions } from '@hooks/usePermissions';
import styled from '@xstyled/styled-components';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';

/**
 * Needed as we have not added these icons yet to `@aircall/icons` package,
 * nor to their respective extensions.
 */
/* istanbul ignore next */
function getRouteIcon({ to, icon: Icon }: SidebarItem) {
  switch (to) {
    case '/numbers':
      return <NumbersIcon />;
    case '/conversations':
      return <ConversationCenterIcon />;
    case '/stats':
      return <AnalyticsIcon />;
    case '/live_monitoring':
    case '/live_monitoring_plus':
      return <LiveMonitoringIcon />;
    case '/activity_feed':
      return <ActivityFeedIcon />;
    case '/users':
      return <UsersIcon />;
    case '/integrations/connected-integrations':
      return <IntegrationsIcon />;
    case '/calls':
      return <CallSettingsIcon />;
    default:
      return <Icon />;
  }
}

function getItemLabelAndPosition(to: string) {
  switch (to) {
    case USERS_ROUTE:
      return {
        label: 'sidenav.all_users_link',
        position: 2,
        hasSeparator: () => true,
      };
    case TEAMS_ROUTE:
      return {
        label: 'sidenav.all_teams_link',
        position: 3,
      };
    default:
      return {};
  }
}

/**
 * The `end` prop indicates that the link will only be
 * "active" if its route is strictly equal to the current path.
 */
const NavLinkStrictActive = styled(NavLink).attrs({ end: true })``;

const StyledSidenavDropdownContainer = styled(SidenavDropdownContainer)`
  max-height: 380px;
`;

function MainRoutes({ sidenavItems, expanded }: Readonly<MainRoutesProps>) {
  const features = useFeatures();
  const { t } = useTranslation();
  const { checkAccess } = usePermissions();

  const location = useLocation();
  const currentPath = location.pathname;

  const funnelId = useRef<string>('');
  const [openModalId, setOpenModalId] = useState<string | undefined>(undefined);

  const { addons } = useCompanyAddons();
  const { isAddonEnabled } = useGetEnableTrialAddonsFeatureFlags();

  return (
    <>
      <Flex as='ul' margin='0' padding='0' gap='xxs' flexDirection='column'>
        {sidenavItems.map((item) => {
          if (!item.enabled(features) || item.hidden?.(features)) {
            return null;
          }

          const addonConfig = SIDENAV_ITEMS_ADDONS_CONFIG[item.to];

          const isAddonActive = Boolean(
            addonConfig && getActiveAddonTrialByName(addonConfig.addonName, addons, isAddonEnabled)
          );

          const itemRestricted = isRouteRestricted(item, checkAccess);

          const commonSidenavItemProps: SidenavItemProps = {
            indicator: (
              <SidenavItemIndicator color='icon-interactive-secondary'>
                {getRouteIcon(item)}
              </SidenavItemIndicator>
            ),
            title: <SidenavItemTitle>{t(item.title)}</SidenavItemTitle>,
            tooltip: t(item.title),
            expanded,
            restricted: itemRestricted,
            badge: isAddonActive ? addonConfig.badge : null,
            'data-test': item.dataTest,
            component: NavLink,
            to: item.to,
            active: 'current-page',
          };

          if (!item.children?.length) {
            return (
              <SidenavItemLi key={item.key}>
                <SidenavItem {...commonSidenavItemProps} />
              </SidenavItemLi>
            );
          }

          return (
            <SidenavItemLi key={item.key}>
              <StyledSidenavDropdownContainer
                {...commonSidenavItemProps}
                active={isParentRouteActive(item, currentPath)}
                component='button'
              >
                {item.children.map((child) => {
                  const hasSeparator = Boolean(child.hasSeparator?.(features));

                  if (
                    !child.enabled(features) ||
                    (child.display && !child.display?.(features)) ||
                    child.hidden?.(features)
                  ) {
                    if (hasSeparator) {
                      return <SidenavDropdownSeparator key={child.key} />;
                    }

                    return null;
                  }

                  if (child.key === 'statsAnalyticsPlusAd') {
                    return (
                      <StatsAnalyticsPlusAdItem
                        key={child.key}
                        dataTest={child.dataTest}
                        onClick={(newFunnelId) => {
                          funnelId.current = newFunnelId;

                          setOpenModalId('statsAnalyticsPlusAd');
                        }}
                      />
                    );
                  }

                  const isChildPartOfAddon = Boolean(
                    isAddonActive && addonConfig.includedChildren?.includes(child.to)
                  );

                  const isChildRestricted = itemRestricted || isRouteRestricted(child, checkAccess);

                  const isChildParentRoute = item.to === child.to;

                  return (
                    <Fragment key={child.key}>
                      <SidenavDropdownItem
                        to={child.to}
                        active='current-page'
                        component={isChildParentRoute ? NavLinkStrictActive : NavLink}
                        data-test={child.dataTest}
                      >
                        <Flex justifyContent='space-between' alignItems='center' flex={1} gap='xxs'>
                          <SidenavDropdownItemLabel>{t(child.title)}</SidenavDropdownItemLabel>
                          {isChildPartOfAddon && !isChildRestricted ? (
                            <Flex
                              alignItems='center'
                              justifyContent='center'
                              data-test={`${child.dataTest}-add-on-badge`}
                            >
                              {addonConfig.badge}
                            </Flex>
                          ) : null}
                          {isChildRestricted ? (
                            <Flex
                              alignItems='center'
                              justifyContent='center'
                              data-test={`${child.dataTest}-lock-badge`}
                            >
                              <LockCircledIcon />
                            </Flex>
                          ) : null}
                        </Flex>
                      </SidenavDropdownItem>
                      {hasSeparator && <SidenavDropdownSeparator />}
                    </Fragment>
                  );
                })}
              </StyledSidenavDropdownContainer>
            </SidenavItemLi>
          );
        })}
      </Flex>
      {openModalId === 'statsAnalyticsPlusAd' ? (
        <AnalyticsPlusAdModal
          show
          onHide={() => setOpenModalId(undefined)}
          funnelId={funnelId.current}
        />
      ) : null}
    </>
  );
}

export function SidenavMain({ expanded }: Readonly<SidenavMainProps>) {
  const { menuItems: extensionsMenuItems } = useModuleFederationContext();

  const sidenavItems = useMemo(() => {
    const extensionMenuItemsParsed = extensionsMenuItems.map<SidebarItem>((item) =>
      formatToSidebarItem({ ...item, ...getItemLabelAndPosition(item.to) })
    );

    const sortedItems = sortByPosition([
      ...extensionMenuItemsParsed,
      ...Object.values(SIDEBAR_ITEMS_CONFIG),
    ]);

    return sortedItems.reduce<SidebarItem[]>((items, item) => {
      /**
       * We group users and teams routes
       */
      if (item.key === USERS_AND_TEAMS_GROUP) {
        const usersAndTeams = sortedItems.filter((sortedItem) =>
          item.groupedRoutes?.includes(sortedItem.to)
        );

        return [...items, { ...item, children: usersAndTeams }];
      }
      if (item.to === USERS_ROUTE || item.to === TEAMS_ROUTE) {
        return [...items];
      }

      return [...items, item];
    }, []);
  }, [extensionsMenuItems]);

  const { hasScrollableContentBottom, onScrollHandler, scrollableContainerRef } =
    useHasScrollableContent<HTMLDivElement>();

  return (
    <ScrollableContainer
      ref={scrollableContainerRef}
      onScroll={onScrollHandler}
      $hasScrollableContentBottom={hasScrollableContentBottom}
    >
      <MainRoutes expanded={expanded} sidenavItems={sidenavItems} />
    </ScrollableContainer>
  );
}
