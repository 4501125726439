import { SidenavItem, SidenavItemIndicator, SidenavItemTitle } from '@aircall/tractor-v2';
import { ReactComponent as CompanyIcon } from '@assets/icons/sidenav/company_icon.svg';
import { PERMISSIONS_ACTIONS, RESOURCE } from '@constants/permissions.constants';
import { ACCOUNT_ROUTE } from '@constants/routes.constants';
import { Avatar, getInitials } from '@dashboard/library';
import { useGlobalData } from '@hooks/useGlobalData/useGlobalData';
import { usePermissions } from '@hooks/usePermissions';
import { NavLink } from 'react-router-dom';

const companyResources = [
  RESOURCE.COMPANY_GENERAL,
  RESOURCE.COMPANY_PLAN,
  RESOURCE.COMPANY_BILLING,
  RESOURCE.COMPANY_SECURITY,
  RESOURCE.COMPANY_ROLES,
];

export function CompanyRoutes({ expanded }: Readonly<{ expanded: boolean }>) {
  const { currentCompany } = useGlobalData();
  const { checkAccess } = usePermissions();

  const hasAccessToCompany = companyResources.some((res) =>
    checkAccess(PERMISSIONS_ACTIONS.READ, res)
  );

  return (
    <SidenavItem
      indicator={
        <>
          {currentCompany.logoUrl ? (
            <Avatar
              variant='default'
              initials={getInitials(currentCompany.name)}
              src={currentCompany.logoUrl}
            />
          ) : null}
          {!currentCompany.logoUrl ? (
            <SidenavItemIndicator color='icon-interactive-secondary'>
              <CompanyIcon />
            </SidenavItemIndicator>
          ) : null}
        </>
      }
      title={<SidenavItemTitle>{currentCompany.name}</SidenavItemTitle>}
      restricted={!hasAccessToCompany}
      disabled={!hasAccessToCompany}
      tooltip={currentCompany.name}
      expanded={expanded}
      component={NavLink}
      to={ACCOUNT_ROUTE}
      active='current-page'
    />
  );
}
